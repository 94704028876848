@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;0,900;1,400&display=swap');
.font-title{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    background: linear-gradient(to left, #70507C 0%,#733A38 50%, #DA3234 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.font-title-white{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    background: linear-gradient(to left, #fff 0%,#fff 50%, #fff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.font-number {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 40px;
    background: linear-gradient(to right, #CD5C95 0%, #CD5C95 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.font-title-sec {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 28px;
    background: linear-gradient(to right, #CD5C95 0%, #CD5C95 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.font-subtitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 22px;
    background: linear-gradient(to right, #50BCBA 0%, #50BCBA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.font-title-black {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 34px;
    background: linear-gradient(to right, #1A1919 0%, #1A1919 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.font-title-black-2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 26px;
    background: linear-gradient(to right, #CD5C95 0%, #CD5C95 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.font-title-black-1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 26px;
    background: linear-gradient(to right, #50BCBA 0%, #50BCBA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.font-title-article {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 24px;
    background: linear-gradient(to right, #1A1919 0%, #1A1919 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.font-title-purp {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 34px;
    background: linear-gradient(to right, #CD5C95 0%, #CD5C95 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.font-subtitle-white {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 22px;
    background: linear-gradient(to right, #fff 0%, #fff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.font-paragraphe{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color:"#1A1919" !important;
}
.fontPara{
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    background: linear-gradient(to right, #BDC6CB 0%, #BDC6CB 100%);
    color: "#799492" !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontParaWhite{
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    background: linear-gradient(to right, #ffffff 0%, #ffffff 100%);
    color: "#799492" !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontParaBold{
    font-family: 'Satoshi', sans-serif;
    font-weight: 500;
    background: linear-gradient(to right, #ffffff 0%, #ffffff 100%);
    color: "#799492" !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontParaPetit{
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    background: linear-gradient(to right, #BDC6CB 0%, #BDC6CB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontParaPetitWhite{
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    background: linear-gradient(to right, #fff 0%, #fff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontClassic{
    font-family: 'Satoshi', sans-serif;
}
.fontThin{
    font-family: 'Satoshi', sans-serif;
    font-weight: 200;
    color: #BDC6CB;
}
.fontKThin{
    font-family: 'Kiona', sans-serif;
    font-weight: 200;
    color: #BDC6CB;
}
.fontKBold{
    font-family: 'Kiona-SemiBold', sans-serif;
    font-weight: 200;
    color: #BDC6CB;
}
.fontKBoldGold{
    font-family: 'Kiona-SemiBold', sans-serif;
    font-weight: 200;
    color: #F5ECD0;
}
.fontKionaGoldBold{
    font-family: 'Kiona-SemiBold', sans-serif;
    font-weight: 500;
    color: #D7AD61;
}
.fontBold{
    font-family: 'Satoshi', sans-serif;
    font-weight: 600;
    color: #F5ECD0;
}
.bigWord{
    font-family: 'Kiona-Bold', sans-serif;
    color:"#B68D40";
    background: linear-gradient(to right, #F5ECD0 0%, #D7AD61 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
}
.bigWordThin{
    font-family: 'Kiona', sans-serif;
    color:"#B68D40";
    background: linear-gradient(to right, #F5ECD0 0%, #D7AD61 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
}

.fontKionaGold{
    font-family: 'Kiona', sans-serif;
    color:"#B68D40";
    font-weight: 500;
    background: linear-gradient(to right, #D7AD61 0%, #D7AD61 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}
.titleCustom{
    color: #D7AD61;
    font-family: 'Satoshi', sans-serif;
    font-weight: 700;
    background: linear-gradient(to right, #D7AD61 0%, #D7AD61 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.button1{
    border-width: 1px;
    border-radius: 10Px;
    border-color: "##DAA686";
}
.text-button1{
    color: "##DAA686"
}
.divGen{
    background-color: #0F2022;
    margin-bottom: 0px;
}

.fontBtn {
    font-family: 'Kiona-Bold', sans-serif;
    font-size: 13px;
    align-self: center;
    background: linear-gradient(to right, #B68D40 0%, #B68D40 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontBtn2 {
    font-family: 'Kiona-Bold', sans-serif;
    font-size: 13px;
    align-self: center;
    background: linear-gradient(to right, #BDC6CB 0%, #BDC6CB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#rotating-text {
    animation: rotation 30s infinite linear; /*CHANGE SPEED*/
    width: 150px;
    z-index: 30;
    align-items: center;
    left: -70px;
    top: -70px;
    position: absolute;
}
.bold{
    font-weight: 700;
    cursor: pointer;
}
@keyframes rotation {
from {
transform: rotate(0deg);
}

to {
transform: rotate(360deg);
}
}
#rotating-text textPath { 
    /*---------------- CHANGE TEXT APPEARANCE ----------------*/
    font-size: 65px; 
    font-family: 'Kiona-SemiBold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 20px; 
    fill: #B68D40; 
}
.justify{
    text-align: justify !important;
}
/* .cursor {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: var(--width);
    height: var(--height);
    transform: translate(calc(var(--x) - var(--width) / 2), calc(var(--y) - var(--height) / 2));
    transition: 150ms width cubic-bezier(0.39, 0.575, 0.565, 1),
                150ms height cubic-bezier(0.39, 0.575, 0.565, 1),
                150ms transform cubic-bezier(0.39, 0.575, 0.565, 1);
    z-index: 1;
    pointer-events: none;
    will-change: transform;
  } */
/*   @media (pointer: fine) {
    .cursor {
      display: block;
    }
  }
  .cursor::after {
    content: '';
    position: absolute;
    color: #F5ECD0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--radius);
    border: 2px solid #F5ECD0;
    opacity: 0.5;
    -webkit-transform: scale(var(--scale));
            transform: scale(var(--scale));
    transition: 300ms opacity cubic-bezier(0.39, 0.575, 0.565, 1),
                300ms transform cubic-bezier(0.39, 0.575, 0.565, 1),
                150ms border-radius cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  html {
    cursor: url("data:image/svg+xml,%3Csvg height='6' width='6' viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' fill='%23eee' r='4'/%3E%3C/svg%3E") 3 3, auto;
    overflow-x: hidden;
  }   */

  .playerCss{
    width: 700px;
    height: 900px;
  }

  @keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #f0f2f5;
    overflow: hidden;
}

.background span {
    width: 19vmin;
    height: 19vmin;
    border-radius: 19vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 33;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}


.background span:nth-child(0) {
    color: #dd5c9d;
    top: 25%;
    left: 69%;
    animation-duration: 126s;
    animation-delay: -28s;
    transform-origin: -9vw 15vh;
    box-shadow: 38vmin 0 5.098260505724008vmin currentColor;
}
.background span:nth-child(1) {
    color: #dd5c9d;
    top: 80%;
    left: 25%;
    animation-duration: 54s;
    animation-delay: -8s;
    transform-origin: 6vw -8vh;
    box-shadow: 38vmin 0 5.582174077055484vmin currentColor;
}
.background span:nth-child(2) {
    color: #dd5c9d;
    top: 88%;
    left: 62%;
    animation-duration: 66s;
    animation-delay: -113s;
    transform-origin: -17vw 7vh;
    box-shadow: 38vmin 0 5.710930280490287vmin currentColor;
}
.background span:nth-child(3) {
    color: #ef7d3c;
    top: 84%;
    left: 90%;
    animation-duration: 52s;
    animation-delay: -10s;
    transform-origin: -21vw 20vh;
    box-shadow: 38vmin 0 5.6586221599123565vmin currentColor;
}
.background span:nth-child(4) {
    color: #dd5c9d;
    top: 93%;
    left: 74%;
    animation-duration: 156s;
    animation-delay: -62s;
    transform-origin: 9vw -5vh;
    box-shadow: -38vmin 0 4.970953579084677vmin currentColor;
}
.background span:nth-child(5) {
    color: #ef7d3c;
    top: 79%;
    left: 97%;
    animation-duration: 89s;
    animation-delay: -146s;
    transform-origin: -23vw 21vh;
    box-shadow: -38vmin 0 5.239087410734319vmin currentColor;
}
.background span:nth-child(6) {
    color: #50bcba;
    top: 37%;
    left: 33%;
    animation-duration: 151s;
    animation-delay: -67s;
    transform-origin: 22vw 16vh;
    box-shadow: 38vmin 0 5.709510301632946vmin currentColor;
}
.background span:nth-child(7) {
    color: #dd5c9d;
    top: 85%;
    left: 62%;
    animation-duration: 100s;
    animation-delay: -158s;
    transform-origin: 14vw -20vh;
    box-shadow: -38vmin 0 5.364790950816328vmin currentColor;
}
.background span:nth-child(8) {
    color: #dd5c9d;
    top: 43%;
    left: 39%;
    animation-duration: 52s;
    animation-delay: -28s;
    transform-origin: -8vw 13vh;
    box-shadow: -38vmin 0 4.905567966808618vmin currentColor;
}
.background span:nth-child(9) {
    color: #ef7d3c;
    top: 49%;
    left: 79%;
    animation-duration: 28s;
    animation-delay: -8s;
    transform-origin: -6vw 19vh;
    box-shadow: 38vmin 0 4.761344732095229vmin currentColor;
}
.background span:nth-child(10) {
    color: #ef7d3c;
    top: 44%;
    left: 87%;
    animation-duration: 175s;
    animation-delay: -31s;
    transform-origin: 20vw 7vh;
    box-shadow: 38vmin 0 4.865458768495687vmin currentColor;
}
.background span:nth-child(11) {
    color: #ef7d3c;
    top: 18%;
    left: 30%;
    animation-duration: 82s;
    animation-delay: -51s;
    transform-origin: 24vw 2vh;
    box-shadow: -38vmin 0 5.367325892808361vmin currentColor;
}
.background span:nth-child(12) {
    color: #dd5c9d;
    top: 4%;
    left: 5%;
    animation-duration: 148s;
    animation-delay: -164s;
    transform-origin: -1vw -22vh;
    box-shadow: 38vmin 0 5.260081050056159vmin currentColor;
}
.background span:nth-child(13) {
    color: #ef7d3c;
    top: 69%;
    left: 50%;
    animation-duration: 26s;
    animation-delay: -88s;
    transform-origin: -4vw -4vh;
    box-shadow: -38vmin 0 4.7517570328978245vmin currentColor;
}
.background span:nth-child(14) {
    color: #50bcba;
    top: 17%;
    left: 73%;
    animation-duration: 151s;
    animation-delay: -37s;
    transform-origin: 22vw 16vh;
    box-shadow: 38vmin 0 5.709510301632946vmin currentColor;
}